import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { Logo, Page, PageConfig, Template, Theme } from "./types";
import { toRgb } from "./helpers";
import { templates } from "./templates";

export type PageConfigContext = {
  pageConfig: PageConfig;
  setTheme: Dispatch<SetStateAction<Theme>>;
  setPageConfig: Dispatch<SetStateAction<PageConfig>>;
  selectedPage: Page;
  setSelectedPage: (page: Page) => void;
  selectedTemplate: Template;
  setSelectedTemplate: (template: Template) => void;
  logo: Logo | null;
  setLogo: (file: Logo | null) => void;
};

const INIT_PAGE_CONFIG: PageConfig = {
  theme: templates.Light.theme,
  logo_url: templates.Light.logo,
  picture_url: "https://img.propelauth.com/2a27d237-db8c-4f82-84fb-5824dfaedc87.png",
  site_display_name: "PropelAuth Demo",
  has_google_login: true,
  has_github_login: false,
  has_microsoft_login: false,
  has_slack_login: false,
  has_linkedin_login: false,
  has_passwordless_login: false,
  has_any_social_login: true,
  has_sso_login: false,
  has_password_login: true,
  only_extra_login_is_passwordless: false,
  has_orgs: true,
  require_username: false,
  require_name: false,
  require_profile_picture: true,
  allow_public_signups: true,
  is_authenticated: false,
  email_confirmed: false,
  login_redirect_url: "",
  home_redirect_url: "",
  is_test_env: false,
  show_powered_by: true,
};

const PageContext = createContext<PageConfigContext>({
  pageConfig: INIT_PAGE_CONFIG,
  setTheme: () => {},
  setPageConfig: () => {},
  selectedPage: Page.Login,
  setSelectedPage: () => {},
  selectedTemplate: Template.Light,
  setSelectedTemplate: () => {},
  logo: null,
  setLogo: () => {},
});

export type PageProviderProps = {
  children?: ReactNode;
};

const PAGE_CONFIG_LOCAL_STORAGE_KEY = "__page_config";
const SELECTED_PAGE_LOCAL_STORAGE_KEY = "__selected_page";
const SELECTED_TEMPLATE_LOCAL_STORAGE_KEY = "__selected_template";

function getInitialPageConfig(): PageConfig {
  if (!localStorage) {
    return INIT_PAGE_CONFIG;
  }
  const pageConfig = localStorage.getItem(PAGE_CONFIG_LOCAL_STORAGE_KEY)
  if (pageConfig) {
    try {
      return JSON.parse(pageConfig)
    } catch (e) {
      return INIT_PAGE_CONFIG
    }
  } else {
    return INIT_PAGE_CONFIG
  }
}

function getInitialSelectedPage(): Page {
  if (!localStorage) {
    return Page.Login
  }
  const page = localStorage.getItem(SELECTED_PAGE_LOCAL_STORAGE_KEY)
  if (!page) {
    return Page.Login
  } else if (page === Page.Login) {
    return Page.Login
  } else if (page === Page.Signup) {
    return Page.Signup
  } else if (page === Page.Org) {
    return Page.Org
  } else {
    return Page.Login
  }
}

function getInitialSelectedTemplate(): Template {
  if (!localStorage) {
    return Template.Light
  }
  const page = localStorage.getItem(SELECTED_TEMPLATE_LOCAL_STORAGE_KEY)
  if (!page) {
    return Template.Light
  } else if (page === Template.Light) {
    return Template.Light
  } else if (page === Template.Dark) {
    return Template.Dark
  } else if (page === Template.Earth) {
    return Template.Earth
  } else if (page === Template.Custom) {
    return Template.Custom
  } else {
    return Template.Light
  }
}

export const PageConfigProvider = ({ children }: PageProviderProps) => {
  const [selectedPage, setSelectedPage] = useState(getInitialSelectedPage());
  const [pageConfig, setPageConfigInner] = useState(getInitialPageConfig());
  const [selectedTemplate, setSelectedTemplate] = useState(getInitialSelectedTemplate());
  const [logo, setLogo] = useState<Logo | null>(null);

  // Using a heartbeat because the config is too large to really watch for changes
  const [saveConfigHeartbeat, setSaveConfigHeartbeat] = useState(0);
  const setPageConfig: Dispatch<SetStateAction<PageConfig>> = (x) => {
    setPageConfigInner(x)
    setSaveConfigHeartbeat(h => h + 1)
  }

  useEffect(() => {
    if (localStorage) {
      localStorage.setItem(PAGE_CONFIG_LOCAL_STORAGE_KEY, JSON.stringify(pageConfig))
    }
  }, [saveConfigHeartbeat])

  useEffect(() => {
    if (localStorage) {
      localStorage.setItem(SELECTED_PAGE_LOCAL_STORAGE_KEY, selectedPage)
    }
  }, [selectedPage])

  useEffect(() => {
    if (localStorage) {
      localStorage.setItem(SELECTED_TEMPLATE_LOCAL_STORAGE_KEY, selectedTemplate)
    }
  }, [selectedTemplate])

  const setTheme: Dispatch<SetStateAction<Theme>> = (update) => {
    setPageConfig((currentPageConfig) => {
      const newTheme = typeof update === "function" ? update(currentPageConfig.theme) : update;
      const partialPageConfig = { theme: newTheme };
      return { ...currentPageConfig, ...partialPageConfig };
    });
  };

  const value = {
    pageConfig,
    setPageConfig,
    setTheme,
    selectedPage,
    setSelectedPage,
    selectedTemplate,
    setSelectedTemplate,
    logo,
    setLogo,
  };
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export const usePageConfig = () => {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error("usePageConfig must be used within a PageConfigProvider");
  }
  return context;
};

export const useTheme = () => {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error("usePageConfig must be used within a PageConfigProvider");
  }
  return { theme: context.pageConfig.theme, setTheme: context.setTheme };
};

export const setHexColor = (
  name: keyof Theme,
  hexValue: string,
  setTheme: Dispatch<SetStateAction<Theme>>,
  setSelectedTemplate: (template: Template) => void
) => {
  setTheme((theme) => {
    const rgbValue = toRgb(hexValue);
    const newValuesInTheme = { [name]: rgbValue };
    return { ...theme, ...newValuesInTheme };
  });
  setSelectedTemplate(Template.Custom);
};
