import styled from "styled-components";

export const Section = styled.div`
  display: block;
  padding: 20px 0;
`;

export const Label = styled.label`
  display: block;
  padding: 0 2px 15px 2px;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
`;
