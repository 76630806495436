import { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { Popover } from "../../_shared/Popover";
import { Label, Section } from "./_shared";
import { usePageConfig } from "../../_shared/PropelAuthPageConfigProvider";
import { PageConfig, Template } from "../../_shared/types";

const UploadButtonContainer = styled.div`
  display: block;
  width: 100%;
  position: relative;
  max-width: inherit;
  cursor: pointer;
  transition: 0.2s;
`;

const UploadButton = styled.label`
  display: block;
  appearance: none;
  font-style: normal;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  border: none;
  border-radius: 8px;
  color: #000;
  text-align: left;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  white-space: nowrap;
  width: auto;
  margin: 0;
  padding: 12px 15px;
  background: #eee;
  cursor: pointer;
  transition: 0.2s;
`;

const UploadIcon = styled.span`
  display: block;
  position: absolute;
  pointer-events: none !important;
  z-index: 10;
  left: auto;
  right: 15px;
  top: 14px;
  bottom: 0;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: rgb(100, 100, 100);
    }
  }
`;

const UploadInput = styled.input`
  display: none;
`;

const ConfirmContainer = styled.div`
  display: block;
  padding: 15px;

  p {
    display: block;
    margin: 0;
    padding: 0 0 15px 0;
    font-style: normal;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 1.6;
  }

  button {
    display: block;
    width: 100%;
    padding: 10px;
    font-style: normal;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    border-radius: 8px;
    border: 1px solid #eee;
    background: #eee;
    color: #3f3f3f;
    cursor: pointer;
  }
`;

export const UploadLogo = () => {
  const { setPageConfig, setSelectedTemplate, logo, setLogo } = usePageConfig();
  const [confirmRef, setConfirmRef] = useState<HTMLDivElement | null>(null);
  const [showConfirm, setShowConfirm] = useState(false);

  function handleUpload(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      const fileUrl = URL.createObjectURL(e.target.files[0]);
      setLogo({
        name: e.target.files[0].name,
        url: fileUrl,
      });
      setPageConfig((pageConfig: PageConfig) => {
        return { ...pageConfig, ...{ logo_url: fileUrl } };
      });
      setSelectedTemplate(Template.Custom);
    }
  }

  function clearUpload() {
    setLogo(null);
    setPageConfig((pageConfig: PageConfig) => {
      return { ...pageConfig, ...{ logo_url: "" } }; // Fallback to default?
    });
    setShowConfirm(false);
  }

  if (!logo) {
    return (
      <Section>
        <Label>Logo</Label>
        <UploadButtonContainer>
          <UploadInput type={"file"} id={"logo"} onChange={handleUpload} accept="image/*" />
          <UploadButton htmlFor={"logo"}>Upload</UploadButton>
          <UploadIcon>
            <svg>
              <path d="M8.70953 7.28992C8.52953 7.10992 8.27953 6.99992 7.99953 6.99992C7.71953 6.99992 7.46953 7.10992 7.28953 7.28992L4.28953 10.2899C4.10123 10.4782 3.99544 10.7336 3.99544 10.9999C3.99544 11.2662 4.10123 11.5216 4.28953 11.7099C4.47784 11.8982 4.73323 12.004 4.99953 12.004C5.26583 12.004 5.52123 11.8982 5.70953 11.7099L6.99953 10.4099V14.9999C6.99953 15.5499 7.44953 15.9999 7.99953 15.9999C8.54953 15.9999 8.99953 15.5499 8.99953 14.9999V10.4099L10.2895 11.6999C10.4695 11.8899 10.7195 11.9999 10.9995 11.9999C11.1975 11.9996 11.391 11.9408 11.5556 11.8307C11.7202 11.7207 11.8485 11.5644 11.9244 11.3815C12.0003 11.1987 12.0204 10.9975 11.9822 10.8032C11.944 10.6089 11.8491 10.4304 11.7095 10.2899L8.70953 7.28992V7.28992ZM11.9995 3.99992C11.9695 3.99992 11.9295 3.99992 11.8995 4.00992C11.653 2.79561 10.9643 1.71611 9.96702 0.980715C8.96975 0.245318 7.73495 -0.093586 6.50201 0.029708C5.26907 0.153002 4.12583 0.729711 3.29394 1.64802C2.46204 2.56634 2.00076 3.76083 1.99953 4.99992C1.99953 5.10992 2.00953 5.21992 2.01953 5.32992C1.40973 5.61344 0.894683 6.06688 0.53616 6.63582C0.177637 7.20477 -0.00913156 7.86507 -0.00174204 8.53752C0.00564748 9.20996 0.206882 9.866 0.577821 10.4269C0.94876 10.9879 1.47365 11.4299 2.08953 11.6999C2.03953 11.4699 1.99953 11.2399 1.99953 10.9999C1.99953 10.1699 2.33953 9.41992 2.87953 8.87992L5.87953 5.87992C6.15759 5.6009 6.48799 5.37952 6.85179 5.22847C7.21558 5.07741 7.60562 4.99965 7.99953 4.99965C8.39344 4.99965 8.78348 5.07741 9.14728 5.22847C9.51107 5.37952 9.84147 5.6009 10.1195 5.87992L13.1195 8.87992C13.6595 9.41992 13.9995 10.1699 13.9995 10.9999C13.9995 11.1599 13.9795 11.3199 13.9495 11.4699C15.1695 10.7799 15.9995 9.49992 15.9995 7.99992C15.9995 5.78992 14.2095 3.99992 11.9995 3.99992Z" />
            </svg>
          </UploadIcon>
        </UploadButtonContainer>
      </Section>
    );
  }

  return (
    <Section>
      <Label>Logo</Label>
      <Popover
        show={showConfirm}
        setShow={setShowConfirm}
        referenceElement={confirmRef}
        placement={"bottom-end"}
        width={"200px"}
      >
        <ConfirmContainer>
          <p>Are you sure you want to remove this file?</p>
          <button onClick={clearUpload}>Remove</button>
        </ConfirmContainer>
      </Popover>
      <UploadButtonContainer ref={setConfirmRef}>
        <UploadButton onClick={() => setShowConfirm(true)}>{logo.name}</UploadButton>
        <UploadIcon>
          <svg>
            <path d="M9.41 8l3.29-3.29c.19-.18.3-.43.3-.71a1.003 1.003 0 00-1.71-.71L8 6.59l-3.29-3.3a1.003 1.003 0 00-1.42 1.42L6.59 8 3.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71L8 9.41l3.29 3.29c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71L9.41 8z"></path>
          </svg>
        </UploadIcon>
      </UploadButtonContainer>
    </Section>
  );
};
