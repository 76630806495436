export function getFakeOrgInfo() {
  const allOrgs = [{
    id: "1",
    name: "Acme Co",
  }, {
    id: "2",
    name: "SideProject",
  }];
  const roles = ["Owner", "Admin", "Member"];
  const fetchInfoForOrg = async (orgId: string) => {
    if (orgId === "1") {
      return {
        invitee_possible_roles: ["Owner", "Admin", "Member"],
        pending_invites: [{
          email: "chaz@acme.co",
          role: "Member",
        }],
        users: [{
          user_id: "1",
          email: "andrew@acme.co",
          role: "Owner",
          possible_roles: ["Owner", "Admin", "Member"],
          can_be_deleted: true,
        }, {
          user_id: "2",
          email: "kayla@acme.co",
          role: "Admin",
          possible_roles: ["Owner", "Admin", "Member"],
          can_be_deleted: true,
        }, {
          user_id: "3",
          email: "josh@acme.co",
          role: "Admin",
          possible_roles: ["Owner", "Admin", "Member"],
          can_be_deleted: true,
        }],
        can_setup_saml: true,
        is_saml_enabled: true,
      };
    } else if (orgId === "2") {
      return {
        invitee_possible_roles: ["Owner", "Admin", "Member"],
        pending_invites: [],
        users: [{
          user_id: "1",
          email: "andrew@acme.co",
          role: "Owner",
          possible_roles: ["Owner", "Admin", "Member"],
          can_be_deleted: true,
        }],
        can_setup_saml: true,
        is_saml_enabled: false,
      };
    } else {
      throw new Error("Unknown org");
    }
  };
  return { allOrgs, roles, fetchInfoForOrg };
}