export type RgbColor = {
  red: number;
  green: number;
  blue: number;
};

export type Theme = {
  background_color: RgbColor;
  background_text_color: RgbColor;
  foreground_color: RgbColor;
  foreground_text_color: RgbColor;
  foreground_secondary_text_color: RgbColor;
  success_button_color: RgbColor;
  success_button_text_color: RgbColor;
  error_button_color: RgbColor;
  error_button_text_color: RgbColor;
  border_color: RgbColor;
};

export enum Page {
  Login = "Login",
  Signup = "Signup",
  Org = "Org",
}

export interface PageConfig {
  theme: Theme;
  logo_url: string;
  picture_url: string;
  site_display_name: string;
  has_google_login: boolean;
  has_github_login: boolean;
  has_microsoft_login: boolean;
  has_slack_login: boolean;
  has_linkedin_login: boolean;
  has_passwordless_login: boolean;
  has_any_social_login: boolean;
  has_sso_login: boolean;
  has_password_login: boolean;
  only_extra_login_is_passwordless: boolean;
  has_orgs: boolean;
  require_username: boolean;
  require_name: boolean;
  require_profile_picture: boolean;
  allow_public_signups: boolean;
  is_authenticated: boolean;
  email_confirmed: boolean;
  login_redirect_url: string;
  home_redirect_url: string;
  is_test_env: boolean;
  show_powered_by: boolean;
}

export enum Template {
  Custom = "Custom",
  Light = "Light",
  Earth = "Earth",
  Dark = "Dark",
}

export type Logo = {
  name: string;
  url: string;
};
