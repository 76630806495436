import { useState } from "react";
import styled from "styled-components";
import { Label, Section } from "./_shared";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { Popover } from "../../_shared/Popover";
import { setHexColor, usePageConfig, useTheme } from "../../_shared/PropelAuthPageConfigProvider";
import { toHex } from "../../_shared/helpers";

const ColorButtonsContainer = styled.div`
  display: block;
  margin-top: -15px;
`;

const ColorButtonContainer = styled.div`
  display: block;
  width: 100%;
  margin: 16px 0;
  position: relative;
  max-width: inherit;
  cursor: pointer;
  transition: 0.2s;
`;

const ColorButton = styled.button`
  display: block;
  appearance: none;
  font-style: normal;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  border: none;
  border-radius: 8px;
  color: #000;
  text-align: left;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  white-space: nowrap;
  width: inherit;
  max-width: inherit;
  margin: 0;
  padding: 13px 15px;
  background: #eee;
  cursor: pointer;
  transition: 0.2s;
`;

const ColorPreview = styled.span`
  display: block;
  position: absolute;
  pointer-events: none !important;
  z-index: 8;
  left: auto;
  right: 14px;
  top: 12px;
  bottom: 0;
`;

type ColorCircleProps = {
  backgroundColor: string;
};

const ColorCircle = styled.span<ColorCircleProps>`
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 6px;
  display: inline-block;
  border: 1px solid #ddd;
  background: ${(props) => props.backgroundColor};
`;

const ColorPopoverContainer = styled.div`
  display: block;
  padding: 15px;

  input {
    display: block;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    justify-content: center;
    align-items: center;
    font-style: normal;
    text-align: left;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    width: calc(200px - 25px);
    margin: 0;
    margin-top: 15px;
    padding: 10px 12px;
    background: inherit;
    font-family: "Inter";
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    text-transform: uppercase;
    border-width: 1px;
    border-radius: 8px;
    border-style: solid;
    border-color: #eee;
    color: #000;
    outline: none;
  }
`;

// prettier-ignore
export const EditColors = () => {
  const { theme, setTheme } = useTheme();
  const { setSelectedTemplate } = usePageConfig();
  const setBackgroundColor = (color: string) => setHexColor("background_color", color, setTheme, setSelectedTemplate);
  const setBackgroundTextColor = (color: string) => setHexColor("background_text_color", color, setTheme, setSelectedTemplate);
  const setBorderColor = (color: string) => setHexColor("border_color", color, setTheme, setSelectedTemplate);
  const setForegroundColor = (color: string) => setHexColor("foreground_color", color, setTheme, setSelectedTemplate);
  const setForegroundTextColor = (color: string) => setHexColor("foreground_text_color", color, setTheme, setSelectedTemplate);
  const setForegroundSecondaryTextColor = (color: string) => setHexColor("foreground_secondary_text_color", color, setTheme, setSelectedTemplate);
  const setButtonColor = (color: string) => setHexColor("success_button_color", color, setTheme, setSelectedTemplate);

  return (
    <Section>
      <Label>Colors</Label>
      <ColorButtonsContainer>
        <Color name={"Background"} colorHex={toHex(theme.background_color)} setColorHex={setBackgroundColor} />
        <Color name={"Background text"} colorHex={toHex(theme.background_text_color)} setColorHex={setBackgroundTextColor} />
        <Color name={"Border"} colorHex={toHex(theme.border_color)} setColorHex={setBorderColor} />
        <Color name={"Foreground"} colorHex={toHex(theme.foreground_color)} setColorHex={setForegroundColor} />
        <Color name={"Foreground text"} colorHex={toHex(theme.foreground_text_color)} setColorHex={setForegroundTextColor} />
        <Color name={"Foreground secondary text"} colorHex={toHex(theme.foreground_secondary_text_color)} setColorHex={setForegroundSecondaryTextColor} />
        <Color name={"Button"} colorHex={toHex(theme.success_button_color)} setColorHex={setButtonColor} />
      </ColorButtonsContainer>
    </Section>
  );
};

type ColorProps = {
  name: string;
  colorHex: string;
  setColorHex: (color: string) => void;
};

const Color = ({ name, colorHex, setColorHex }: ColorProps) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [buttonRef, setButtonRef] = useState<HTMLDivElement | null>(null);

  return (
    <div>
      <ColorButtonContainer ref={setButtonRef}>
        <ColorButton onClick={() => setShowColorPicker(!showColorPicker)}>{name}</ColorButton>
        <ColorPreview>
          <ColorCircle backgroundColor={colorHex} />
        </ColorPreview>
      </ColorButtonContainer>
      <Popover
        referenceElement={buttonRef}
        show={showColorPicker}
        setShow={setShowColorPicker}
        placement={"bottom-end"}
      >
        <ColorPopoverContainer>
          <HexColorPicker color={colorHex} onChange={setColorHex} />
          <HexColorInput color={colorHex} onChange={setColorHex} />
        </ColorPopoverContainer>
      </Popover>
    </div>
  );
};
