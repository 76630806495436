import amplitude, { Config } from "amplitude-js";

let logEvent = (eventType: string, eventProperties?: object) => {
  console.log(`Track [${eventType}]: ${JSON.stringify(eventProperties || {})}`)
}

if (process.env.NODE_ENV === "production") {
  logEvent = (eventType, eventProperties) => {
    amplitude.getInstance().logEvent("[Demo] " + eventType, eventProperties)
  }
}

let initLogging = () => {
  console.log("Logging initialized")
}

if (process.env.NODE_ENV === "production") {
  initLogging = () => {
    const options: Config = {
      includeGclid: true,
      includeFbclid: true,
      includeUtm: true,
      includeReferrer: true,
      apiEndpoint: "log.propelauth.com/log",
      sameSiteCookie: "Lax",
    }
    amplitude.getInstance().init("df4703100596a0e25b85de9b4630369f", undefined, options)
  }
}

let setUserId = (userId: string) => {
  console.log("Set user id to " + userId)
}

if (process.env.NODE_ENV === "production") {
  setUserId = (userId) => {
    amplitude.getInstance().setUserId(userId)
  }
}

export { initLogging, logEvent, setUserId }
