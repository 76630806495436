import { useEffect, useState } from "react";
import { Drawer } from "./Drawer/Drawer";
import "./App.scss";
import { Login, Org, OrgPageConfig, Signup } from "@propelauth/hosted-auth-page-components";
import { usePageConfig } from "./_shared/PropelAuthPageConfigProvider";
import { Page, PageConfig } from "./_shared/types";
import { getFakeOrgInfo } from "./_shared/org_fake_data";
import { logEvent } from "./_shared/Logging";

function App() {
  const [open, setOpen] = useState(true);
  const { selectedPage, pageConfig } = usePageConfig();
  useEffect(() => {
    logEvent("Visited demo page")
  }, [])

  return (
    <div className="app">
      <div className="drawer" data-open={open}>
        <Drawer />
      </div>
      <div className="main" data-open={open}>
        <button className="drawer-toggle" onClick={() => setOpen(!open)}>
          <svg viewBox="0 0 16 16">
            <path d="M1 4h14c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1s.45 1 1 1zm14 8H1c-.55 0-1 .45-1 1s.45 1 1 1h14c.55 0 1-.45 1-1s-.45-1-1-1zm0-5H1c-.55 0-1 .45-1 1s.45 1 1 1h14c.55 0 1-.45 1-1s-.45-1-1-1z"></path>
          </svg>
        </button>
        {getInnerPage(selectedPage, pageConfig)}
      </div>
    </div>
  );
}

function getInnerPage(selectedPage: Page, pageConfig: PageConfig) {
  if (selectedPage === Page.Login) {
    return (
      <Login
        pageConfig={pageConfig}
        onClickGoogleLogin={() => null}
        onClickGithubLogin={() => null}
        onClickSlackLogin={() => null}
        onClickMicrosoftLogin={() => null}
        onClickLinkedinLogin={() => null}
        onClickPasswordlessLogin={() => null}
        onClickSSOLogin={() => null}
        allowPublicSignups={true}
        onClickSignupLink={() => null}
        onClickForgotPasswordLink={() => null}
        onSubmitLogin={async () => {}}
        loading={false}
        errors={[]}
      />
    );
  } else if (selectedPage === Page.Signup) {
    return (
      <Signup
        pageConfig={pageConfig}
        onClickLoginLink={() => null}
        onClickGoogleLogin={() => null}
        onClickGithubLogin={() => null}
        onClickSlackLogin={() => null}
        onClickMicrosoftLogin={() => null}
        onClickLinkedinLogin={() => null}
        onClickPasswordlessLogin={() => null}
        onClickSSOLogin={() => null}
        loading={false}
        onSubmitSignup={async () => {}}
      />
    );
  } else if (selectedPage === Page.Org) {
    const fakeOrgInfo = getFakeOrgInfo();
    const orgPageConfig: OrgPageConfig = {
      theme: pageConfig.theme,
      logo_url: pageConfig.logo_url,
      site_display_name: pageConfig.site_display_name,
      has_orgs: true,
      users_can_create_orgs: true,
      all_orgs: fakeOrgInfo.allOrgs,
      is_test_env: false,
      show_powered_by: false,
      roles: fakeOrgInfo.roles,
    };
    return (
      <Org
        pageConfig={orgPageConfig}
        onClickCreateOrgLink={() => null}
        userSpecifiedOrgId={"1"}
        fetchInfoForOrg={fakeOrgInfo.fetchInfoForOrg}
      />
    );
  }
}

export default App;
