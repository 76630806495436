export const templates = {
  Light: {
    logo: process.env.PUBLIC_URL + "/light.png",
    theme: {
      background_color: { red: 248, green: 248, blue: 248 },
      background_text_color: { red: 0, green: 0, blue: 0 },
      foreground_color: { red: 255, green: 255, blue: 255 },
      foreground_text_color: { red: 0, green: 0, blue: 0 },
      foreground_secondary_text_color: { red: 50, green: 50, blue: 50 },
      success_button_color: { red: 72, green: 89, blue: 193 },
      success_button_text_color: { red: 255, green: 255, blue: 255 },
      error_button_color: { red: 174, green: 25, blue: 30 },
      error_button_text_color: { red: 255, green: 255, blue: 255 },
      border_color: { red: 228, green: 228, blue: 228 },
    },
  },
  Earth: {
    logo: process.env.PUBLIC_URL + "/earth.png",
    theme: {
      background_color: { red: 61, green: 58, blue: 46 },
      background_text_color: { red: 255, green: 255, blue: 255 },
      foreground_color: { red: 255, green: 255, blue: 255 },
      foreground_text_color: { red: 0, green: 0, blue: 0 },
      foreground_secondary_text_color: { red: 50, green: 50, blue: 50 },
      success_button_color: { red: 208, green: 138, blue: 100 },
      success_button_text_color: { red: 255, green: 255, blue: 255 },
      error_button_color: { red: 174, green: 25, blue: 30 },
      error_button_text_color: { red: 255, green: 255, blue: 255 },
      border_color: { red: 226, green: 225, blue: 218 },
    },
  },
  Dark: {
    logo: process.env.PUBLIC_URL + "/dark.png",
    theme: {
      background_color: { red: 18, green: 18, blue: 17 },
      background_text_color: { red: 255, green: 255, blue: 255 },
      foreground_color: { red: 36, green: 36, blue: 34 },
      foreground_text_color: { red: 255, green: 255, blue: 255 },
      foreground_secondary_text_color: { red: 237, green: 237, blue: 237 },
      success_button_color: { red: 118, green: 94, blue: 229 },
      success_button_text_color: { red: 255, green: 255, blue: 255 },
      error_button_color: { red: 174, green: 25, blue: 30 },
      error_button_text_color: { red: 255, green: 255, blue: 255 },
      border_color: { red: 70, green: 70, blue: 70 },
    },
  },
};
