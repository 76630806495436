import styled from "styled-components";
import { ReactNode, SelectHTMLAttributes } from "react";
import { Label, Section } from "./_shared";
import { usePageConfig } from "../../_shared/PropelAuthPageConfigProvider";
import { Page } from "../../_shared/types";

const SelectContainer = styled.div`
  display: block;
  width: 100%;
  position: relative;
  max-width: inherit;
  cursor: pointer;
  transition: 0.2s;

  path {
    fill: #000;
  }
`;

const SelectElement = styled.select`
  display: block;
  appearance: none;
  font-style: normal;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  border: none;
  border-radius: 8px;
  color: #000;
  text-align: left;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  white-space: nowrap;
  width: inherit;
  max-width: inherit;
  margin: 0;
  padding: 12px 15px;
  background: #eee;
  cursor: pointer;
  transition: 0.2s;

  &:focus {
    outline: none;
  }
`;

const DownArrowIcon = styled.span`
  display: block;
  position: absolute;
  pointer-events: none !important;
  z-index: 10;
  left: auto;
  right: 15px;
  top: 14px;
  bottom: 0;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: rgb(100, 100, 100);
    }
  }
`;

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  children: ReactNode;
};

const Select = ({ children, ...rest }: SelectProps) => {
  return (
    <SelectContainer>
      <SelectElement {...rest}>{children}</SelectElement>
      <DownArrowIcon>
        <svg>
          <path d="M12 5c-.28 0-.53.11-.71.29L8 8.59l-3.29-3.3a1.003 1.003 0 00-1.42 1.42l4 4c.18.18.43.29.71.29s.53-.11.71-.29l4-4A1.003 1.003 0 0012 5z"></path>
        </svg>
      </DownArrowIcon>
    </SelectContainer>
  );
};

export const SelectPage = () => {
  const {selectedPage, setSelectedPage} = usePageConfig()
  return (
    <Section>
      <Label htmlFor={"page"}>Page</Label>
      <Select id={"page"} value={selectedPage} onChange={(e) => setSelectedPage(e.target.value as Page)}>
        <option value={Page.Login}>Login</option>
        <option value={Page.Signup}>Signup</option>
        <option value={Page.Org}>Org</option>
      </Select>
    </Section>
  );
};
