import { RgbColor } from "./types";

export function toHex(color: RgbColor) {
  return "#" + componentToHex(color.red) + componentToHex(color.green) + componentToHex(color.blue);
}

export function toRgb(hex: string): RgbColor {
  const hexValueOnly = hex.startsWith("#") ? hex.substring(1) : hex;
  const bigint = parseInt(hexValueOnly, 16);
  const red = (bigint >> 16) & 255;
  const green = (bigint >> 8) & 255;
  const blue = bigint & 255;
  return {red, green, blue}
}

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}