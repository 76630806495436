import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { PageConfigProvider } from "./_shared/PropelAuthPageConfigProvider";
import { initLogging } from "./_shared/Logging";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import EmbeddedApp from "./EmbeddedApp";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div>Not found</div>
  },
  {
    path: "/ext",
    element: <EmbeddedApp />
  }
]);

initLogging()

root.render(
  <React.StrictMode>
    <PageConfigProvider>
      <RouterProvider router={router} />
    </PageConfigProvider>
  </React.StrictMode>,
);
