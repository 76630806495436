import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core";
import styled from "styled-components";

const PopoverElement = styled.div`
  display: none;
  z-index: 10;

  &[data-show] {
    display: block;
  }
`;

const InnerElement = styled.div`
  display: block;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  border-color: #eee;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: scale(0);
  transition: 0.1s;

  &[data-show] {
    display: block;
    opacity: 1;
    transform: scale(1);
  }
`;

type PopoverProps = {
  referenceElement: HTMLDivElement | null;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  placement?: Placement;
  width?: string;
  minWidth?: string;
  children: ReactNode;
};

export const Popover = ({ referenceElement, show, setShow, placement, width, minWidth, children }: PopoverProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [innerElement, setInnerElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [{ name: "offset", options: { offset: [0, 5] } }],
  });

  useEffect(() => {
    function outsideClickHandler(e: MouseEvent) {
      if (e.target) {
        const p = popperElement;
        const t = e.target as Element;
        const r = referenceElement as Element;
        if (!p?.contains(t) && !r.contains(t)) {
          setShow(false);
        }
      }
    }

    if (show) {
      if (update) {
        update();
        animatePopover("in", popperElement, innerElement);
        document.addEventListener("click", outsideClickHandler);
      }
    } else {
      animatePopover("out", popperElement, innerElement);
      return;
    }

    return () => document.removeEventListener("click", outsideClickHandler);
  }, [show, innerElement, popperElement, referenceElement, setShow, update]);

  return (
    <PopoverElement ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <InnerElement
        ref={setInnerElement}
        style={{
          width: width ?? "auto",
          minWidth: minWidth ?? "auto",
        }}
      >
        {children}
      </InnerElement>
    </PopoverElement>
  );
};

function animatePopover(dir: "in" | "out", el: Element | null, inner: Element | null) {
  switch (dir) {
    case "in":
      el?.setAttribute("data-show", "");
      setTimeout(() => inner?.setAttribute("data-show", ""), 10);
      return;
    case "out":
      inner?.removeAttribute("data-show");
      setTimeout(() => el?.removeAttribute("data-show"), 100);
      return;
    default:
      break;
  }
}
