import styled from "styled-components";
import { EditColors } from "./sections/EditColors";
import { SelectLayout } from "./sections/SelectLayout";
import { SelectPage } from "./sections/SelectPage";
import { SelectTemplate } from "./sections/SelectTemplate";
import { UploadLogo } from "./sections/UploadLogo";

const DrawerHeader = styled.h3`
  display: block;
  font-family: Inter;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  letter-spacing: normal;
  margin: 0;
  padding: 20px;
  color: #000;
  border-bottom: 1px solid #eee;
  background: #fff;

  @media (max-width: 768px) {
    margin-left: 50px;
  }
`;

const DrawerInner = styled.div`
  display: block;
  height: calc(100vh - 81px);
  overflow-y: scroll;
  padding: 10px 20px;
`;

export const Drawer = () => {
  return (
    <div className="drawer-contents">
      <DrawerHeader>Customize your auth pages</DrawerHeader>
      <DrawerInner>
        <SelectPage />
        <SelectTemplate />
        <UploadLogo />
        {/*<SelectLayout />*/}
        <EditColors />
      </DrawerInner>
    </div>
  );
};
