import "./App.scss";
import { Login } from "@propelauth/hosted-auth-page-components";
import { PageConfig, RgbColor } from "./_shared/types";
import { useSearchParams } from "react-router-dom";
import { validate as uuidValidate } from "uuid";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

const ORIGIN = process.env.NODE_ENV === "production" ? "https://app.propelauth.com" : "https://app.propelauth.localhost";

function App() {
  const [searchParams] = useSearchParams();
  const [backgroundColor, setBackgroundColor] = useState({ red: 248, green: 248, blue: 248 });
  const [backgroundTextColor, setBackgroundTextColor] = useState({ red: 248, green: 248, blue: 248 });
  const [foregroundColor, setForegroundColor] = useState({ red: 248, green: 248, blue: 248 });
  const [foregroundTextColor, setForegroundTextColor] = useState({ red: 15, green: 15, blue: 15 });
  const [foregroundSecondaryTextColor, setForegroundSecondaryTextColor] = useState({
    red: 54,
    green: 54,
    blue: 54,
  });
  const [successButtonColor, setSuccessButtonColor] = useState({ red: 80, green: 200, blue: 120 });
  const [successButtonTextColor, setSuccessButtonTextColor] = useState({
    red: 247,
    green: 247,
    blue: 247,
  });
  const [errorButtonColor, setErrorButtonColor] = useState({ red: 207, green: 34, blue: 46 });
  const [errorButtonTextColor, setErrorButtonTextColor] = useState({
    red: 255,
    green: 255,
    blue: 255,
  });
  const [borderColor, setBorderColor] = useState({ red: 228, green: 228, blue: 228 });
  const [mainImageUrl, fallbackImageUrl] = getImageUrls(searchParams);
  const logoUrl = useLogo({mainImageUrl, fallbackImageUrl});

    useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.origin !== ORIGIN) {
        return;
      }

      parseAndSetColor(setBackgroundColor, event.data.background_color)
      parseAndSetColor(setBackgroundTextColor, event.data.background_text_color)
      parseAndSetColor(setForegroundColor, event.data.foreground_color)
      parseAndSetColor(setForegroundTextColor, event.data.foreground_text_color)
      parseAndSetColor(setForegroundSecondaryTextColor, event.data.foreground_secondary_text_color)
      parseAndSetColor(setSuccessButtonColor, event.data.success_button_color)
      parseAndSetColor(setSuccessButtonTextColor, event.data.success_button_text_color)
      parseAndSetColor(setErrorButtonColor, event.data.error_button_color)
      parseAndSetColor(setErrorButtonTextColor, event.data.error_button_text_color)
      parseAndSetColor(setBorderColor, event.data.border_color)
    }

    window.addEventListener("message", handleMessage, false);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const pageConfig: PageConfig = {
    allow_public_signups: true,
    email_confirmed: false,
    has_any_social_login: true,
    has_github_login: false,
    has_google_login: false,
    has_linkedin_login: false,
    has_microsoft_login: false,
    has_orgs: false,
    has_password_login: true,
    has_passwordless_login: true,
    has_slack_login: false,
    has_sso_login: false,
    home_redirect_url: "",
    is_authenticated: false,
    is_test_env: false,
    login_redirect_url: "",
    logo_url: logoUrl,
    only_extra_login_is_passwordless: false,
    require_name: false,
    require_profile_picture: false,
    require_username: false,
    show_powered_by: false,
    site_display_name: "",
    picture_url: "",
    theme: {
      background_color: backgroundColor,
      background_text_color: backgroundTextColor,
      foreground_color: foregroundColor,
      foreground_text_color: foregroundTextColor,
      foreground_secondary_text_color: foregroundSecondaryTextColor,
      success_button_color: successButtonColor,
      success_button_text_color: successButtonTextColor,
      error_button_color: errorButtonColor,
      error_button_text_color: errorButtonTextColor,
      border_color: borderColor,
    },
  };

  return (
    <div className="app">
      <div className="main">
        <Login
          pageConfig={pageConfig}
          onClickGoogleLogin={() => null}
          onClickGithubLogin={() => null}
          onClickSlackLogin={() => null}
          onClickMicrosoftLogin={() => null}
          onClickLinkedinLogin={() => null}
          onClickPasswordlessLogin={() => null}
          onClickSSOLogin={() => null}
          allowPublicSignups={true}
          onClickSignupLink={() => null}
          onClickForgotPasswordLink={() => null}
          onSubmitLogin={async () => {
          }}
          loading={false}
          errors={[]}
        />
      </div>
    </div>
  );
}

function useLogo({ mainImageUrl, fallbackImageUrl }: { mainImageUrl: string, fallbackImageUrl: string }) {
  const [isValid, setIsValid] = useState(false);
  const [isFallbackValid, setIsFallbackValid] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = mainImageUrl;
    image.onload = () => {
      setIsValid(true);
    };
    image.onerror = () => {
      setIsValid(false);
    };
  }, [mainImageUrl]);

  useEffect(() => {
    const image = new Image();
    image.src = fallbackImageUrl;
    image.onload = () => {
      setIsFallbackValid(true);
    };
    image.onerror = () => {
      setIsFallbackValid(false);
    };
  }, [fallbackImageUrl]);

  if (isValid) {
    return mainImageUrl;
  } else if (isFallbackValid) {
    return fallbackImageUrl;
  } else {
    return "https://img.propelauth.com/1d571211-a4ef-4e98-b69e-4bfb1e2393c6.png"
  }

}

function getImageUrls(searchParams: URLSearchParams) {
  const imageId = searchParams.get("imageId");
  const projectId = searchParams.get("projectId");
  const isValidProjectId = projectId && uuidValidate(projectId);
  const isValidImageId = imageId && uuidValidate(imageId);
  if (isValidProjectId && isValidImageId) {
    return [`https://img.propelauth.com/${projectId}_${imageId}.png`, `https://img.propelauth.com/${imageId}.png`]
  } else if (isValidImageId) {
    return [`https://img.propelauth.com/${imageId}.png`, `https://img.propelauth.com/${imageId}.png`]
  } else {
    return ["https://img.propelauth.com/1d571211-a4ef-4e98-b69e-4bfb1e2393c6.png", "https://img.propelauth.com/1d571211-a4ef-4e98-b69e-4bfb1e2393c6.png"]
  }
}

function parseAndSetColor(setColor: Dispatch<SetStateAction<RgbColor>>, possibleColor?: string) {
  const parsedColor = possibleColor && hexToRgb(possibleColor);
  if (parsedColor) {
    setColor(parsedColor);
  }
}

function hexToRgb(hex: string): RgbColor | undefined {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return undefined;
  }
  const red = parseInt(result[1], 16);
  const green = parseInt(result[2], 16);
  const blue = parseInt(result[3], 16);
  if (isNaN(red) || isNaN(green) || isNaN(blue)) {
    return undefined;
  }
  return { red, green, blue };
}

export default App;
